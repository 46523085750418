<template>
    <v-row v-if="project && version && !loadingDoc">
        <v-col>
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <version-toolbar :version="version" v-on:back="back()" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('versions.actions.search_doc')"
                        solo
                        clearable
                        dense
                        rounded
                        prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="2" md="2">
                    <v-treeview
                        :open="openFoldersDoc"
                        class="book-treeview"
                        activatable
                        open-on-click
                        :items="treeviewDoc.filter((t) => t.id !== '_changelog')"
                        dense
                        hoverable
                        v-on:update:active="showPage"
                        :active="activeItem"
                    >
                        <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.children">
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                            <v-icon v-else v-text="'mdi-file-document-outline'"> </v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <span v-if="item.children" class="folder">{{ item.name }}</span>
                            <span v-else class="file">{{ item.name }}</span>
                        </template>
                    </v-treeview>
                </v-col>
                <v-col :cols="treeviewResult.length > 0 ? 8 : 10" class="documentation-article">
                    <router-view></router-view>
                </v-col>
                <v-col v-if="treeviewResult.length > 0" cols="2" md="2">
                    <v-card-subtitle v-text="$t('versions.actions.search_result')"></v-card-subtitle>
                    <v-treeview
                        :open="openFoldersDoc"
                        class="result-treeview"
                        activatable
                        open-on-click
                        :items="treeviewResult"
                        dense
                        hoverable
                        v-on:update:active="showPage"
                        :active="activeItem"
                    >
                        <template v-slot:prepend="{ item, open }">
                            <v-icon v-if="item.children">
                                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                            </v-icon>
                            <v-icon v-else v-text="'mdi-file-document-outline'"> </v-icon>
                        </template>
                        <template v-slot:label="{ item }">
                            <span v-if="item.children" class="folder">{{ item.name }}</span>
                            <span v-else class="file">{{ item.name }}</span>
                        </template>
                    </v-treeview>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import versionToolbar from '@/components/versions/header';
import Mark from 'mark.js';

export default {
    name: 'project-doc',
    data: () => ({
        version: undefined,
        search: undefined,
        searchTimer: undefined,
        markInstance: undefined,
    }),
    components: { versionToolbar },
    watch: {
        project: function () {
            this.version = this.project.versions.find((v) => v.id === this.$route.params.version);
        },
        search: function (query) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }

            this.searchTimer = setTimeout(() => {
                if (query) {
                    this.$router.replace(
                        {
                            query: Object.assign({ ...this.$route.query }, { search: query }),
                        },
                        () => {},
                    );
                } else {
                    this.$router.replace(
                        {
                            query: Object.assign({ ...this.$route.query }, { search: undefined }),
                        },
                        () => {},
                    );
                }
                this.searchArticleDoc({
                    project_id: this.$route.params.project_id,
                    version_id: this.$route.params.version,
                    term: query,
                });

                this.markInstance = new Mark(document.querySelector('.documentation-article'));

                this.markInstance.mark(this.search, {
                    element: 'mark',
                    className: 'search-highlight',
                    exclude: ['.revision-toc *'],
                });
            }, 800);
        },
    },
    mounted() {
        this.version = this.project
            ? this.project.versions.find((v) => v.id === this.$route.params.version)
            : undefined;

        this.search = this.$route.query.search ? this.$route.query.search : undefined;
    },
    computed: {
        ...mapGetters(['project', 'treeviewDoc', 'loadingDoc', 'openFoldersDoc', 'articleDoc', 'treeviewResult']),
        activeItem() {
            return this.articleDoc ? [this.articleDoc.id] : [];
        },
        hasResult() {
            return this.treeviewResult.length > 0;
        },
    },
    methods: {
        ...mapActions(['findTreeviewDoc', 'showArticleDoc', 'searchArticleDoc']),
        back() {
            this.$router.push({
                name: 'project-versions',
                params: { project_id: this.project.id },
            });
        },
        showPage(p) {
            if (p.length > 0) {
                const page = p[0];

                this.showArticleDoc(page);

                let pageToNavigate = `/projects/${this.$route.params.project_id}/versions/${this.$route.params.version}/docs/${page}`;
                if (this.search) {
                    pageToNavigate += `?search=${this.search}`;
                }
                this.$router.push(pageToNavigate);
            }
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.findTreeviewDoc({
                project_id: to.params.project_id,
                version_id: to.params.version,
                page: to.params.pathMatch,
                term: to.query.search ? to.query.search : undefined,
            });
        });
    },
};
</script>

<style>
.book-treeview {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
}
.result-treeview {
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: 400;
    font-style: italic;
}
.v-treeview-node__level {
    width: 16px;
}
.v-treeview-node__label {
    overflow: auto !important;
    text-overflow: unset !important;
    white-space: inherit !important;
    padding-top: 0.4rem;
}

.v-treeview-node__label span.file {
    font-weight: 400;
    text-transform: none;
}
</style>
