<template>
    <v-toolbar dense flat class="mb-4" color="transparent">
        <v-toolbar-title v-if="version.development">
            {{ $t('projects.versions.development') }}
        </v-toolbar-title>
        <v-toolbar-title v-if="!version.development">
            <v-icon small> mdi-calendar </v-icon>
            {{ $t('projects.versions.outAt') }} {{ formatDate(version.date) }}
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-toolbar-title
            class="mx-2 title"
            v-text="version.label ? 'Version ' + version.label : 'Version ' + version.name"
        ></v-toolbar-title>
        <v-chip :color="color(version)" dark label small>
            <v-icon left dark small v-if="version.status === 'major'">mdi-check-decagram</v-icon>
            <v-icon left dark small v-if="version.status === 'minor'">mdi-alert-decagram</v-icon>
            <v-icon left dark small v-if="version.status === 'fix'">mdi-decagram</v-icon>
            {{ $t('lists.versionStatus.' + version.status) }}
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn text small @click="back()" class="mr-4">
            {{ $t('buttons.back') }}
        </v-btn>
    </v-toolbar>
</template>

<script>
import { formatDateMixin } from '@/mixins/dates';
import { projectMemberMixin } from '@/mixins/member';

export default {
    name: 'version-header',
    data: () => ({}),
    props: {
        version: {},
    },
    mixins: [formatDateMixin, projectMemberMixin],
    computed: {
        displayVersion() {
            if (this.version) {
                if (this.version.development) {
                    return this.memberRoles.some((r) => ['productManager', 'productOwner', 'developer'].includes(r));
                } else {
                    return true;
                }
            }

            return false;
        },
    },

    methods: {
        back() {
            this.$emit('back');
        },
        color(version) {
            let color = 'light-blue lighten-1';
            if (version.status === 'major') {
                color = 'light-green darken-1';
            }
            if (version.status === 'minor') {
                color = 'lime lighten-1';
            }
            if (version.status === 'fix') {
                color = 'lime lighten-2';
            }
            return color;
        },
    },
};
</script>

<style></style>
