var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project && _vm.version && !_vm.loadingDoc)?_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('version-toolbar',{attrs:{"version":_vm.version},on:{"back":function($event){return _vm.back()}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('versions.actions.search_doc'),"solo":"","clearable":"","dense":"","rounded":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"2","md":"2"}},[_c('v-treeview',{staticClass:"book-treeview",attrs:{"open":_vm.openFoldersDoc,"activatable":"","open-on-click":"","items":_vm.treeviewDoc.filter(function (t) { return t.id !== '_changelog'; }),"dense":"","hoverable":"","active":_vm.activeItem},on:{"update:active":_vm.showPage},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',{domProps:{"textContent":_vm._s('mdi-file-document-outline')}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('span',{staticClass:"folder"},[_vm._v(_vm._s(item.name))]):_c('span',{staticClass:"file"},[_vm._v(_vm._s(item.name))])]}}],null,false,309770145)})],1),_c('v-col',{staticClass:"documentation-article",attrs:{"cols":_vm.treeviewResult.length > 0 ? 8 : 10}},[_c('router-view')],1),(_vm.treeviewResult.length > 0)?_c('v-col',{attrs:{"cols":"2","md":"2"}},[_c('v-card-subtitle',{domProps:{"textContent":_vm._s(_vm.$t('versions.actions.search_result'))}}),_c('v-treeview',{staticClass:"result-treeview",attrs:{"open":_vm.openFoldersDoc,"activatable":"","open-on-click":"","items":_vm.treeviewResult,"dense":"","hoverable":"","active":_vm.activeItem},on:{"update:active":_vm.showPage},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(item.children)?_c('v-icon',[_vm._v(" "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+" ")]):_c('v-icon',{domProps:{"textContent":_vm._s('mdi-file-document-outline')}})]}},{key:"label",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('span',{staticClass:"folder"},[_vm._v(_vm._s(item.name))]):_c('span',{staticClass:"file"},[_vm._v(_vm._s(item.name))])]}}],null,false,309770145)})],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }